html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
strong{
    font-weight: 700;
}

@font-face {
    font-family: 'iconsregular';
    src: url('./assets/fonts/icons-webfont.eot');
    src: url('./assets/fonts/icons-webfont.svg#iconsregular') format('svg'),
    url('./assets/fonts/icons-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/icons-webfont.woff') format('woff'),
    url('./assets/fonts/icons-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Questrial';
    src: url('./assets/fonts/questrial-regular-webfont.eot');
    src: url('./assets/fonts/questrial-regular-webfont.svg#questrial') format('svg'),
    url('./assets/fonts/questrial-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/questrial-regular-webfont.woff') format('woff'),
    url('./assets/fonts/questrial-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




body{
    font-family: 'Questrial', sans-serif;
    font-size: 1em;
    line-height: 1.4em;
    color: #666;
    background: #fff;
}
a, a:link, a:visited{
    color: #666;
    text-decoration: none;
}
a:hover{
    text-decoration: underline;
}


p{
    margin-bottom: 1.2em;
}

p a, p a:link, p a:visited{
    text-decoration: underline;
    color: #999;
}
p a:hover{
    color: #666;
}

#skrollr-body{
    width: 100%;
}

#main-content{
    width: 900px;
    margin: 0 auto;
    padding-bottom: 100px;
}

.floatleft{
    float: left;
}
.icons{
    font-family: 'iconsregular';
    margin-right:5px;
    font-size: 1.1em;
}
.clear{
    clear: both;
}




header {
    clear: both;
    margin: 20px 0px 20px 0px;
    font-size: 2.1em;
    font-weight: 400;
    line-height: 2em;
}
.headername{
    width: 340px;
}
.headercontact{
    font-size: 0.8em;
    line-height: 2.7em;
    width: 560px;
    text-align: right;
}
.headerresume{
    display: none;
}

#careerchart{
    clear: both;
    display: none;
}

#careerchartImg{
    clear: both;
    width: 100%;
    padding: 10px 0 0 0;
}

#attributes{
    padding: 40px 0 0 0;
}
#attributes aside {
    float: left;
    width: 33%;
    padding: 0 20px 10px 23px;
    box-sizing:border-box;
    -moz-box-sizing:border-box;

}
#attributes aside.first
{
    padding-left: 0px;
}
#attributes aside h2{
    margin: 20px 0 10px 0;
    font-size: 1.6em;
    font-weight: 600;
}

article{
}

.full {
    clear: both;
    margin-top: 30px;
    padding: 0 0 60px 0;
    border-top: solid 1px #eee;
}
.fulldivider{
    clear: both;
    margin-top: 30px;
    padding: 60px 0 0 0;
    border-bottom: solid 1px #eee;
}
.full > h2 {
    margin: 20px 0 0 0;
    font-size: 1.6em;
    font-weight: 400;
}

.full article {
    clear: both;
    width: 100%;
    padding-top: 50px;
    display: inline-block; /* clearfix */
}
.full article div.left{
    width: 450px;
    float: left;
}
.full article div img
{
    width: 400px;
    margin-top: 5px;
}
.full article div.right{
    float: left;
    width: 450px;
    font-size: 0.9em;
}
.full article div.right p.role{
    font-style: italic;
}
.full article h2{
    clear: both;
    margin: 20px 0px 20px 450px;
    font-size: 1.6em;
    font-weight: 400;
}
.scrubber img { display: none;}


.compact {
    clear: both;
    margin-top: 20px;
    padding: 20px 0 50px 0;
    border-top: solid 1px #eee;
}
.compact > h2 {
    margin: 20px 0 20px 0px;
    font-size: 1.6em;
    font-weight: 400;
}
.compact article{
    float: left;
    width: 33%;
    padding-right: 30px;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    font-size: 0.9em;
}
.compact article:nth-of-type(3n+1){
    clear: both;
}
.compact article h2{
    clear: both;
    margin: 20px 0px 8px 0px;
    font-size: 1.6em;
    font-weight: 400;
}
.compact article div.image{
    width: 270px;
    height: 200px;
    margin: 5px 0 8px 0;
    background-size: cover;
    background-position: center center;
}

nav{
    z-index: 9;
    background: #fff;
    position: fixed;
    left: -1px;
    top: 118px;
    border: solid 1px #ddd;
    padding: 10px;
    -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, .1);
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, .1);
    width: 12px;
    overflow: hidden;
    -webkit-transition: width .1s ease-in-out;
    -moz-transition: width .1s ease-in-out;
    -o-transition: width .1s ease-in-out;
    transition: width .1s ease-in-out;
    color: #999;
}
nav:hover{
    -webkit-transition: width .1s ease-in-out;
    -moz-transition: width .1s ease-in-out;
    -o-transition: width .1s ease-in-out;
    transition: width .1s ease-in-out;
    width: 165px;
}
nav div{
    margin-bottom: 3px;
    cursor: pointer;
}
nav div:first-child{
    margin-top: 3px;
}
nav span{
    font-family: iconsregular;
    font-size: 22px;
    padding: 0 10px 0 1px;
}

.errorPageHeader{
    clear:both;
    padding-top: 20px
}


@media screen and (max-width: 970px) {
    #main-content{
        width: 80%;
    }
    header .headercontact{
        text-align: left;
        width: auto;
        line-height: 1em;
    }
    #attributes aside {
        width: 100%;
        clear: both;
        padding: 0px;
    }

    .full > h2 {
        margin: 20px 0px 0px 0px;
    }
    .full article div.left,
    .full article div .scrubber{
        width: 100%;
    }

    .full article div img{
        height: auto;
        width: 100%;
    }
    .full article div.right{
        width: 100%;
    }
    .full article h2{
        margin: 20px 0px 20px 0px;
    }

    .compact article{
        width: 50%;
    }
    .compact article:nth-of-type(3n+1){
        clear: none;
    }
    .compact article:nth-child(2n+0){
        clear: both;
    }
    .compact article div.image{
        height: 200px;
        width: 100%;
    }
}

@media screen and (max-width: 700px) {

    header .headercontact{
        font-size: 0.6em;
        line-height: 1.2em;
    }
    header .headerresume{
        display: block;
        cursor: pointer;
        margin-bottom: 40px;
    }
    header .headerresume:hover{
        text-decoration: underline;
    }
    nav{
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .compact article{
        width: 100%;
        clear: both;
    }
}
