.subtopic{
    clear: both;
    min-height: 220px;
    .left {
        text-align: right;
        img {
            width: 200px !important;
            height: 200px;
            margin-right: 50px;
        }
    }
}

@media screen and (max-width: 970px) {
    .subtopic{
        .left {
            width: 220px !important;
            text-align: left;
            float: left;
            img {
                float: left;
                width: 200px !important;
                height: 200px;
                margin-right: 50px;
            }
        }
        .right{
            float: none !important;
            width: auto !important;
        }
    }
}

@media screen and (max-width: 500px) {
    .subtopic{
        .left {
            width: 170px !important;
            text-align: left;
            float: left;
            img {
                float: left;
                width: 150px !important;
                height: 150px;
                margin-right: 50px;
            }
        }
        .right{
            float: none !important;
            width: auto !important;
        }
    }
}