header {
  clear: both;
  margin: 20px 0px 20px 0px;
  font-size: 2.1em;
  font-weight: 400;
  line-height: 2em;
}

.headername {
  width: 340px;
}

.headercontact {
  font-size: 0.8em;
  line-height: 2.7em;
  width: 560px;
  text-align: right;
}

.headerresume {
  display: none;
}

